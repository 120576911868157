<template>
  <figure class="game-center-item" :class="className">
    <nuxt-link v-if="!game.isFunction" :id="game.title" :to="game?.link" class="cursor-pointer">
      <div class="game-center-item__box">
        <div class="game-center-item__box--image">
          <CommonBaseImg :src="game.image" class="game-list--top" lazy :alt="game.title" />
          <CommonBaseImg
            v-if="game.image_ncc"
            class="icon-ncc"
            :class="game?.title?.toLowerCase()"
            :src="game.image_ncc"
            lazy
            :alt="game.title"
          />
        </div>
        <div class="game-center-item__box--title">
          <span>
            {{ game.title }}
          </span>
          <div v-if="sumJackpot[game.jackpot] > 0" class="jackpot">
            <AnimateNumber :number="sumJackpot[game.jackpot]" :show-coin="true" />
          </div>
        </div>
        <div class="game-center-item__box--des">
          {{ game.description }}
        </div>
      </div>
    </nuxt-link>
    <nuxt-link v-else :id="game.title" :to="game?.link" class="cursor-pointer">
      <div class="game-center-item__box">
        <div class="game-center-item__box--image">
          <CommonBaseImg :src="game.image" class="game-list--top" lazy :alt="game.title" />
          <CommonBaseImg
            v-if="game.image_ncc"
            class="icon-ncc"
            :class="game?.title?.toLowerCase()"
            :src="game.image_ncc"
            lazy
            :alt="game.title"
          />
        </div>
        <div class="game-center-item__box--title">
          <span>
            {{ game.title }}
          </span>
          <div v-if="sumJackpot[game.jackpot] > 0" class="jackpot">
            <AnimateNumber :number="sumJackpot[game.jackpot]" :show-coin="true" />
          </div>
        </div>
        <div class="game-center-item__box--des">
          {{ game.description }}
        </div>
      </div>
    </nuxt-link>
  </figure>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import { PAGE } from '~/constants/router'
import AnimateNumber from '~/components/common/animate-number.vue'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, sumJackpot, jackpot } = storeToRefs(store)
const router = useRouter()

const props = defineProps({
  game: {
    type: Object,
    default: () => ({})
  },
  className: {
    type: String,
    default: ''
  },
  isShowIcon: {
    type: Boolean,
    default: false
  }
})

const openGame = (item: any) => {
  if (item.link) {
    router.push(item.link)
  }
}
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/game-center/game-center-item.scss"></style>
